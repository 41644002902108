import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-top space-between text-xxs">
            <FooterSocial />
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav />
            <div className="footer-copyright">
              Made with &#10084; by{" "}
              <a
                target="_blank"
                href="https://www.linkedin.com/in/arjun-aghera-585525190/"
              >
                Arjun
              </a>
              ,{" "}
              <a
                target="_blank"
                href="https://www.linkedin.com/in/ayush-agrawal-86143a190/"
              >
                Lucifer
              </a>{" "}
              and
              <a
                target="_blank"
                href="https://www.linkedin.com/in/anubhavbagri/"
              >
                {" "}
                Anubhav
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
